$(document).ready(function() {

    $(".header").sticky({topSpacing:0});

    $('.top-carrousel ul').bxSlider({
        mode: 'fade',
        pager: false,
        controls: true,
        auto: true,
        //nextSelector: $('.pageheader .next_btn'),
        //prevSelector: $('.pageheader .prev_btn'),
        nextText: '',
        prevText: '',
        touchEnabled: false
    });

    $('.block').fitVids();


    //equalHeights('.banner','.banners-123');
    imagesToBackground();

    /*
    Create dynamic BXslider
    Needed for blocks: 100
    */
    createBXslider('.bxslider');

    /*
    Extra content toggle
    Needed for blocks: 15
    */
    $('.toggle_extra_content h4').click(function(e) {
        //$(this).fadeToggle();
        $(this).toggleClass('up down');
        $(this).parent().parent().find('.extra_content').slideToggle();
    });

    $('.blog-masonry').masonry({
        itemSelector: '.news-item',
        percentPosition: true
    });
    /*
    Order masonry style
    Needed for blocks: ... (none yet)
    */
    /*
    $('.blocklist').masonry({
        // options
        itemSelector: 'article'
        //,columnWidth: 33%
    });*/

    /*
    Gmap3 functionality
    Needed for blocks: 130
    */
    $('.gmap').each(function( index ) {
        gmap_address = $(this).data('address');
        //console.log(gmap_address);
        $(this).gmap3({
            marker: {
                address: gmap_address,
                events: {
                    click: function(marker, event, data) {
                        window.open("https://maps.google.com/?daddr="+gmap_address);
                    }
                }
            },
            map:{
                options:{
                    zoom:18,
                    scrollwheel: false,
                }
            }
        });
    });


    var filterOnLoad = window.location.hash ? '.'+(window.location.hash).replace('#','') : 'all';
    $('#projecten-mix').mixItUp({
        controls: {
            enable: true
        },
        load: {
            filter: filterOnLoad,
        }
    });

    $('a.filter').click(function() {
        window.location.hash = $(this).data('filter').replace('.', '');
    });


    $("#project-filter").keyup(function(){
        // Delay function invoked to make sure user stopped typing
        delay(function(){
            var $matching = $();
            var inputText = $('#project-filter').val().toLowerCase();

            // Check to see if input field is empty
            if ((inputText.length) > 0) {
                $( '.mix').each(function() {
                    // add item to be filtered out if input text matches items inside the title

                    if($(this).text().toLowerCase().match(inputText)) {
                        $matching = $matching.add(this);
                    }
                    else {
                        // removes any previously matched item
                        $matching = $matching.not(this);
                    }
                });
                $("#projecten-mix").mixItUp('filter', $matching);
            }

            else {
                // resets the filter to show all item if input is empty
                $("#projecten-mix").mixItUp('filter', 'all');
            }
        }, 200 );
    });

    if ($('#instafeed').length > 0)
    {
        var feed = new Instafeed({
            get: 'user',
            target: 'instafeed',
            //userId: '4907848',
            //userId: '3225861549',
            userId: '3226076154',
            //accessToken: '4907848.5babb26.df27d4fd9de54f7aa359b267302ac0c1',
            //accessToken: '3225861549.d0be77f.eb3aa21e152c4bcca0c1ed7d80dc38eb',
            accessToken: '3226076154.dcb8a20.ec131ae01b314145929d27c54ded997b',
            template: '<li><a href="{{link}}" target="_blank" alt="{{caption}}"><img src="{{image}}" /></a></li>',
            after: function() {
                var calcw = $('#instafeed').parent().width() + 30;
                var w = (calcw > 767) ? calcw * 0.166666667 : calcw * 0.333333334;
                $('#instafeed').data('bx-slidewidth', w);
                createBXslider('#instafeed');
            }
        });
        feed.run();
    }

    $("#nieuwsbrief-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/nieuwsbrief.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });

    $("#aanmeld-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/aanmelden.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });


	$('#voorkeur1').change(function(e) {
		var val = $(this).val();
		disbaleVoorkeuren('#voorkeur3', val, $('#voorkeur2').val(), $('#voorkeur3').val() );
	});

	$('#voorkeur2').change(function(e) {
		var val = $(this).val();
		disbaleVoorkeuren('#voorkeur3', $('#voorkeur1').val(), val, $('#voorkeur3').val() );
	});

	$('#voorkeur3').change(function(e) {
		var val = $(this).val();
		disbaleVoorkeuren('#voorkeur3', $('#voorkeur1').val(), $('#voorkeur2').val(), val );
	});
	

});

function disbaleVoorkeuren(voorkeur, val1, val2, val3) {
	$('select option').attr('disabled', false);
	if (val1.length > 0) $("#voorkeur2 option[value='"+val1+"'], #voorkeur3 option[value='"+val1+"']").attr('disabled', 'disabled');
	if (val2.length > 0) $("#voorkeur1 option[value='"+val2+"'], #voorkeur3 option[value='"+val2+"']").attr('disabled', 'disabled');
	if (val3.length > 0) $("#voorkeur1 option[value='"+val3+"'], #voorkeur2 option[value='"+val3+"']").attr('disabled', 'disabled');
}
/*
$(document).scroll(function() { });
*/
$( window ).resize(function() {
    //equalHeights('.banner','.banners-123');


});




function initCoverflow(elm) {

    coverflow = new FWDSimple3DCoverflow(
        {
            //required settings
            coverflowHolderDivId: elm,
            coverflowDataListDivId:"coverflowData",
            displayType:"responsive",
            autoScale:"yes",
            coverflowWidth:940,
            coverflowHeight:538,
            skinPath:"../images/coverflow/skin_white",

            //main settings
            backgroundColor:"transparent",
            backgroundImagePath:"",
            backgroundRepeat:"repeat-x",
            showDisplay2DAlways:"no",
            coverflowStartPosition:$('#'+elm).data('cf-startslide'),
            coverflowTopology:$('#'+elm).data('cf-mode'),
            coverflowXRotation:0,
            coverflowYRotation:0,
            numberOfThumbnailsToDisplayLeftAndRight:"5",
            infiniteLoop:$('#'+elm).data('cf-loop'),
            rightClickContextMenu:"default",

            //thumbnail settings
            thumbnailWidth:400,
            thumbnailHeight:300,
            thumbnailXOffset3D:86,
            thumbnailXSpace3D:78,
            thumbnailZOffset3D:200,
            thumbnailZSpace3D:93,
            thumbnailYAngle3D:70,
            thumbnailXOffset2D:20,
            thumbnailXSpace2D:30,
            thumbnailHoverOffset:100,
            thumbnailBorderSize:0,
            thumbnailBackgroundColor:"#999999",
            thumbnailBorderColor1:"#FFFFFF",
            thumbnailBorderColor2:"#000000",
            transparentImages:"yes",
            thumbnailsAlignment:"center",
            maxNumberOfThumbnailsOnMobile:13,
            showThumbnailsGradient:"yes",
            thumbnailGradientColor1:"rgba(0, 0, 0, 0)",
            thumbnailGradientColor2:"rgba(0, 0, 0, 0)",
            showText:"yes",
            textOffset:10,
            showThumbnailBoxShadow:"yes",
            thumbnailBoxShadowCss:"0px 2px 2px #111111",
            showTooltip:"no",
            dynamicTooltip:"yes",
            showReflection:"no",
            reflectionHeight:60,
            reflectionDistance:0,
            reflectionOpacity:.4,

            //controls settings
            slideshowDelay:$('#'+elm).data('cf-pause'),
            autoplay:$('#'+elm).data('cf-autoplay'),
            disableNextAndPrevButtonsOnMobile:"no",
            controlsMaxWidth:700,
            slideshowTimerColor:"#FFFFFF",
            controlsPosition:"bottom",
            controlsOffset:15,
            showPrevButton:$('#'+elm).data('cf-controls'),
            showNextButton:$('#'+elm).data('cf-controls'),
            showSlideshowButton:$('#'+elm).data('cf-controls'),
            showScrollbar:$('#'+elm).data('cf-pager'),
            disableScrollbarOnMobile:"yes",
            enableMouseWheelScroll:"yes",
            scrollbarHandlerWidth:200,
            scrollbarTextColorNormal:"#000000",
            scrollbarTextColorSelected:"#FFFFFF",
            addKeyboardSupport:"yes",

            //categories settings
            showCategoriesMenu:"no",
            startAtCategory:1,
            categoriesMenuMaxWidth:700,
            categoriesMenuOffset:25,
            categoryColorNormal:"#999999",
            categoryColorSelected:"#FFFFFF",

            //lightbox settings
            addLightBoxKeyboardSupport:"yes",
            showLightBoxNextAndPrevButtons:"yes",
            showLightBoxZoomButton:"yes",
            showLightBoxInfoButton:"yes",
            showLightBoxSlideShowButton:"yes",
            showLightBoxInfoWindowByDefault:"no",
            slideShowAutoPlay:"no",
            lightBoxVideoAutoPlay:"no",
            lightBoxVideoWidth:640,
            lightBoxVideoHeight:480,
            lightBoxIframeWidth:800,
            lightBoxIframeHeight:600,
            lightBoxBackgroundColor:"#000000",
            lightBoxInfoWindowBackgroundColor:"#FFFFFF",
            lightBoxItemBorderColor1:"#fcfdfd",
            lightBoxItemBorderColor2:"#e4FFe4",
            lightBoxItemBackgroundColor:"#333333",
            lightBoxMainBackgroundOpacity:.8,
            lightBoxInfoWindowBackgroundOpacity:.9,
            lightBoxBorderSize:0,
            lightBoxBorderRadius:20,
            lightBoxSlideShowDelay:4000

        });
}

function createBXslider(elm){
    $(elm).each(function(index) {
        c_id = $(this).attr('id');
        //console.log(c_id);
        var options = {};
        options.mode = $(this).data('bx-mode');
        options.auto = $(this).data('bx-auto');
        options.pause = $(this).data('bx-pause');
        options.loop = $(this).data('bx-loop');
        options.pager = $(this).data('bx-pager');
        options.controls = $(this).data('bx-controls');
        options.ticker = $(this).data('bx-ticker');
        options.speed = $(this).data('bx-speed');
        options.minSlides  = $(this).data('bx-minslides') ? $(this).data('bx-minslides') : 1;
        options.maxSlides  = $(this).data('bx-maxslides') ? $(this).data('bx-maxslides') : 1;
        options.slideWidth  = $(this).data('bx-slidewidth') ? $(this).data('bx-slidewidth') : 0;

        options.nextText = '';
        options.prevText = '';
        console.log(options);
        $(this).bxSlider(options);
    });
    return;

    car_id = $(elm).attr('id');
    var cms_options = 'options_'+car_id;
    this[cms_options].value++;
    //console.log(this[cms_options]);
    var options = {};

    // auto
    if ('auto' in this[cms_options]) {
        switch(this[cms_options].auto){
            case '1':
                options.auto = true;
                break;
            default:
                options.auto = false;
        }
    }else{
        options.auto = true;
    }

    // mode
    if ('mode' in this[cms_options]) {
        switch(this[cms_options].mode){
            case '0':
                options.mode = 'fade';
                break;
            case '2':
                options.mode = 'vertical';
                break;
            case '3':
                options.mode = 'horizontal';
                options.ticker = true;
                options.speed = 8000;
                break;
            default:
                options.mode = 'horizontal';
        }
    }else{
        options.mode = 'horizontal';
    }

    //console.log(options);

    $('#'+car_id).bxSlider(options);
}

// set <img src> as a css background-image
/*function imagesToBackground(){
    $('.image-on-bg').each(function() {
        var imageUrl = $(this).find('img').first().attr('src');
        imageUrl = encodeURI(imageUrl);
        $(this).find('img').first().remove();
        $(this).css('background-image', 'url(' + imageUrl + ')');
    });
}*/

function imagesToBackground(){
    $('.image-to-bg').each(function() {
        //var imageHeight = $(this).attr('height');
        var imageUrl = $(this).attr('src');
        imageUrl = encodeURI(imageUrl);
        var parentElement = $(this).closest('div');
        parentClass = '.'+$(this).closest('section').attr('class').split(' ').pop();
        $(this).remove();
        parentElement.addClass('image-block').css('background-image', 'url(' + imageUrl + ')');
        equalHeights('.block > div', parentClass);
    });
}

function equalHeights(element, container){
    if (typeof container === 'undefined') {
        c = $(element).parent();
    } else {
        c = $(container);
    }

    var highestBox = 0;
    c.find(element).each(function(){
        if($(this).height() > highestBox){
            highestBox = $(this).height();
        }
    })
    c.find(element).height(highestBox);

}

/*
function placeholderFallback() {
    if(!Modernizr.input.placeholder) {
        $("input[placeholder], textarea[placeholder]").each(function() {
            if($(this).val()==""){
                $(this).val($(this).attr("placeholder"));
                $(this).focus(function(){
                    if($(this).val()==$(this).attr("placeholder")) {
                        $(this).val("");
                        $(this).removeClass('placeholder');
                    }
                });
                $(this).blur(function(){
                    if($(this).val()==""){
                        $(this).val($(this).attr("placeholder"));
                        $(this).addClass('placeholder');
                    }
                });
            }
        });
        $('form').submit(function(){
            // first do all the checking for required element and form validation.
            // Only remove placeholders before final submission
            var placeheld = $(this).find('[placeholder]');
            for(var i=0; i<placeheld.length; i++){
                if($(placeheld[i]).val() == $(placeheld[i]).attr('placeholder')) {
                    // if not required, set value to empty before submitting
                    $(placeheld[i]).attr('value','');
                }
            }
        });
    }
}*/


// Delay function
var delay = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();

$.validator.setDefaults({
    highlight: function(element) {
        $(element).closest('.form-group').addClass('has-error');
    },
    unhighlight: function(element) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function(error, element) {
        if(element.parent('.input-group').length) {
            //error.insertAfter(element.parent());
            error.appendTo(element.parent().parent());
        } else {
            error.insertAfter(element);
        }
    }
});
